body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', sans-serif;
  background-color: #ffffff;
  color: #333333;
}

a {
  color: #ff9800;
  text-decoration: none;
}

a:hover {
  color: #f57c00;
}

button {
  background-color: #ff9800;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #f57c00;
}